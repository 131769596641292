import React from 'react'
import Link from '../../common/Link'

const ServiceWebRequirementCta = () => (
  <section className="service-web bg-dark py-5">
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="lead text-center">
            <strong>
            ¿Una aplicación web totalmente funcional en tan solo 4 semanas?&nbsp;
            </strong>
            <br />
            <div className="d-inline text-muted">
            Nuestro <h3 className="seo-span">proceso de diseño y desarrollo</h3>
            de aplicaciones web es mucho más rápido porque nuestro equipo trabaja de manera eficaz y ágil.
            </div>
            <br />
            <Link to="/estimate-app-cost/" className="btn btn-info block-xs m-3">
            Discuta sus requisitos 
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebRequirementCta
