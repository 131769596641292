import React from 'react'
import { Category, scrollToForm } from '../../contact/ContactForm'

const ServiceWebCta = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4 col-lg-6">
          <div className="lead text-sm-center text-md-right">
            <h3 className="seo-span seo-strong text-muted">
             Aplicaciones web a medida
            </h3>
            <br />
            <span className="text-service">
            Software de la mejor calidad&nbsp;
            <strong className="span-regular"><br></br>a precios de offshoring</strong>
            </span>
            <br />
            <a
              href="#contact-partial"
              onClick={event => {
                event.preventDefault()
                scrollToForm(Category.WebApps, 0, 500)
              }}
              className="btn btn-outline-info my-2"
              id="custom-web-app-talk-btn"
            >
              ¡Consulta nuestra oferta!
            </a>
          </div>
        </div>
        <div className="col-sm-12 col-md-8 col-lg-6">
          <div className="seo-p">
          Nuestros<h4 className="seo-span"> desarrolladores</h4> son altamente competentes y tienen una amplia experiencia en el desarrollo de &nbsp;
            <h3 className="seo-span">aplicaciones web a medida</h3> para nuestros clientes de todo el mundo. Ofrecemos actualizaciones de back-end y conexiones a la nube. Además, nuestro equipo puede actualizar el front-end existente, lo que permite mejorar la velocidad del software. Los <h4 className="seo-span">desarrolladores</h4> de Zaven son siempre eficaces, con una buena relación coste-eficacia y orientados al cliente.
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebCta
