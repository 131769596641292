import React from 'react'
import Link from '../../common/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons'

const ServiceWebOffer = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            <span className="text-muted seo-regular">Outsourcing del desarrollo</span>
            <br />
            de aplicaciones web
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <p>
          ¿Por qué merece la pena subcontratar los proyectos a Zaven? Porque trabajamos con éxito con clientes de diferentes partes del mundo y sabemos cómo guiarlos en el proceso: Desde el desarrollo de la visión, la consultoría, la creación de prototipos, el diseño de UX, las pruebas de QA, la creación de aplicaciones, la implementación, el RWD y la integración hasta el mantenimiento y apoyo.

          </p>
          <p>
          Nuestro equipo de near-sourcing garantiza eficacia y un alto rendimiento con precios offshore. Para saber qué modelo es el ideal para usted,&nbsp;
            <Link to="/services/poland-software-outsourcing/">
            consulte nuestra guía de subcontratación
            </Link>
            .
          </p>
        </div>
      </div>

      <div className="row pb-5">
        <div className="col-sm-12 col-md-4">
          <div className="seo-h2 text-sm-center text-md-right">
            <span className="text-muted text-regular">
              <strong className="seo-regular">La promesa de la empresa </strong> y
            </span>
            <br />

            <h3 className="seo-span">
              <strong>el ciclo de desarrollo del software</strong>
            </h3>
          </div>
        </div>
        <div className="col-sm-12 col-md-8">
          <p>
          En nuestra empresa, creemos que la satisfacción y la confianza son la base de cada asociación comercial a largo plazo. Para asegurarnos de que nuestra colaboración se desarrolla sin problemas, hemos adoptado las mejores prácticas en materia de desarrollo e implantación de software.

          </p>
          <ul className="fa-ul">
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Soluciones totalmente funcionales y fáciles de usar
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Bajo coste y excelente relación calidad-precio
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              Alta adaptabilidad del usuario
            </li>
            <li>
              <span className="fa-li">
                <FontAwesomeIcon icon={faAngleRight} />
              </span>
              <h4 className="seo-span">Proceso de desarrollo ágil</h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceWebOffer
