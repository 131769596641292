import React from 'react'
import Link from '../../common/Link'

const ServiceWebTags = () => (
  <section className="service-web bg-faded py-5">
    <div className="container">
      <div className="row mb-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            <span className="text-muted seo-regular">Web development</span>
            <br />
            <strong>software team</strong>
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <p>
          Siempre nos aseguramos de asignar las personas más adecuadas a su proyecto porque creemos que un equipo bien ajustado es garantía de éxito. Cada grupo de proyecto está formado por desarrolladores, diseñadores, expertos en UI y profesionales del front-end. Si quiere saber más,
            <Link to="/about/team/">{' '}conozca a nuestro equipo.</Link>.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-4">
          <h2 className="text-sm-center text-md-right">
            <span className="text-muted seo-regular">Soluciones <br></br> de aplicaciones web</span>
            <br />
            para corporaciones
          </h2>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="seo-p">
            Nuestro 
            <h2 className="seo-span"> servicio de desarrollo de aplicaciones web</h2>
            le ayudará a alcanzar sus objetivos de marketing independientemente del sector en el que trabaje: e-learning, ERP, BPA, Social Media, E-commerce, Gaming, innovación, health-tech o lifestyle. 
          </div>
          <ul className="list-unstyled list-inline list-badges">
            <li>
              <span className="badge badge-light">.NET</span>
            </li>
            <li>
              <span className="badge badge-light">.NET MVC</span>
            </li>
            <li>
              <span className="badge badge-light">Ruby on Rails</span>
            </li>
            <li>
              <span className="badge badge-light">HTML5</span>
            </li>
            <li>
              <span className="badge badge-light">ExpressJS</span>
            </li>
            <li>
              <span className="badge badge-light">Zend</span>
            </li>
            <li>
              <span className="badge badge-light">ASO.NET</span>
            </li>
            <li>
              <span className="badge badge-light">Angular JS</span>
            </li>
            <li>
              <span className="badge badge-light">Prototype testing</span>
            </li>
            <li>
              <span className="badge badge-light">QA testing</span>
            </li>
            <li>
              <span className="badge badge-light">Responsive web design</span>
            </li>
            <li>
              <span className="badge badge-light">Support & Maintenance</span>
            </li>
            <li>
              <span className="badge badge-light">Static websites</span>
            </li>
            <li>
              <span className="badge badge-light">
                Mobile-friendly websites
              </span>
            </li>
            <li>
              <span className="badge badge-light">Service integrations</span>
            </li>
          </ul>
          <div className="clearfix" />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 col-md-4" />
        <div className="col-sm-12 col-md-8" />
      </div>
    </div>
  </section>
)

export default ServiceWebTags
